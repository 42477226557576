import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { ProcessContent } from "./process"

export default function WorkWithMe() {
  return (
    <Layout>
      <React.Fragment>
        <section className="flex justify-content-center-space-between align-items-center halves m-y-3">
          <img src="/assets/images/work-with-me.1.jpg"></img>

          <section className="bordered left p-2">
            <h1 className="m-b-2">WORK WITH US</h1>

            <h3>Are you looking for a skilled, reliable, and passionate team of health and wellness enthusiasts to help grow your business? Want to create content that educates, informs, and empowers?</h3>
            <p>
              As <Link to="/services-and-rates">health writers and content creators</Link>, we can help with blogging, social media marketing, newsletters, proofreading, video editing, Pinterest management, marketing materials, and more. We offer monthly packages and hourly support.
            </p>

            <h3 className="m-t-2">Let's Work Together</h3>
            <p>
              To learn more about our health writing, content creation, and social media marketing services, please <Link to="/contact">contact us</Link>. We're currently accepting new clients and are eager to help you create content that educates, informs, and empowers your clients and patients.
            </p>
          </section>
        </section>
        <ProcessContent></ProcessContent>
      </React.Fragment>
    </Layout>
  )
}


