import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"

const process = [
  {title: 'Contact Us', content: <p>We’ll discuss your content creation needs and you’ll choose the best package for your health and wellness business. Our preferred method of communication is <Link to="/contact">email</Link>, but we'd be glad to chat by phone or video conferencing if you’d prefer to <a target="_blank" href="https://calendly.com/alexandra-beane-well-from-within/30min">book a call.</a></p>},
  {title: 'Sign a Contract', content: <p>To ensure we’re on the same page, we’ll have you sign a contract. Or, if you’re not quite ready for that, we can create a sample blog article, social media post, newsletter, or video at a discounted rate. After receiving your sample, you will sign a contract if you’re ready to move forward with our content creation services.</p>},
  {title: 'We Get to Work', content: <p>We'll get to work on your content and email everything over to you upon completion. Our content is typically created in Google Docs or Canva Pro, making it easy to edit and share content with others. If you need revisions, please let us know!</p>},
  {title: 'Use Your Content', content: <p>After receiving your content and royalty free image(s), you can post them wherever you’d like! Post them to your blog, share them on social media, and/or include them in your email newsletter. These are yours to use however you’d like!</p>},
  {title: 'Invoicing', content: <p>For recurring work, you will be billed on the last business day of each month. For one-off assignments, you will be billed immediately after the work has been completed. All invoices are sent via email and must be paid within 30 days.</p>}
]

export default function Process() {
  return (
    <Layout>
      <ProcessContent></ProcessContent>
    </Layout>
  )
}

export function ProcessContent() {
  return (
    <section>
    <div className="flex">
      <section className="bordered top p-2 m-a">
        <h1 className="text-center">The Well From Within Process</h1>
      </section>
    </div>

    <div className="flex justify-content-center-space-between fifths m-y-3">

      {process.map((item, idx) => (
        <section className="text-center">
          <img src={'/assets/images/process-'+(idx + 1)+'.1.jpg'}></img>
          <h3 className="m-y-1">Step {(idx + 1)}: {item.title}</h3>
          {item.content}
        </section>
      ))}

    </div>

  </section>
  )
}